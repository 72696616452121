import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXing,
  faFacebook,
  faGithub,
  faDocker,
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => (
  <>
    <div className="flex flex-row flex-wrap justify-center sm:justify-start border-t pt-6">
      <div className="flex flex-col w-full md:w-auto flex-grow leading-normal mb-6 md:mb-12">
        <span className="uppercase font-semibold">Kontakt</span>
        +49 (0) 251 149 823 30
        <br />
        <a className="no-underline text-black" href="mailto:hello@hochzehn.com">hello@hochzehn.com</a>
        <br />
      </div>
      <div className="flex flex-col w-full md:w-auto flex-grow leading-normal mb-6 md:mb-12">
        <span className="uppercase font-semibold">Münster</span>
        Hafenweg 16
        <br />
        48155 Münster
        <a href="https://goo.gl/maps/ToB6WrPh6p72" className="mr-3" rel="noopener noreferrer" target="_blank">
          Finde uns
        </a>
      </div>
      <div className="flex flex-row md:justify-end w-full md:w-auto flex-grow leading-normal mb-6 md:mb-12">
        <a href="https://www.xing.com/companies/hochzehngmbh%26co.kg" rel="noopener noreferrer" target="_blank">
          <FontAwesomeIcon className="mx-1" icon={faXing} />
        </a>
        <a href="https://business.facebook.com/hochzehn/" rel="noopener noreferrer" target="_blank">
          <FontAwesomeIcon className="mx-1" icon={faFacebook} />
        </a>
        <a href="https://github.com/hochzehn/" rel="noopener noreferrer" target="_blank">
          <FontAwesomeIcon className="mx-1" icon={faGithub} />
        </a>
        <a href="https://hub.docker.com/r/hochzehn/" rel="noopener noreferrer" target="_blank">
          <FontAwesomeIcon className="mx-1" icon={faDocker} />
        </a>
      </div>
    </div>
    <div className="flex flex-row justify-center mb-6">
      <div className="flex flex-col">
        <div>
          <Link to="imprint" className="mr-3 text-black">
            Impressum
          </Link>
          <Link to="privacy" className="mr-3 text-black">
            Datenschutz
          </Link>
          {`© ${new Date().getFullYear()} Hochzehn GmbH & Co. KG`}
        </div>
      </div>
    </div>
  </>
);

export default Footer;
