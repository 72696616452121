import React from 'react';
import { Link } from 'gatsby';

import Navigation from './Navigation';
import BrandImage from '../images/brand.svg';
import LogoImage from '../images/logo.svg';

const Header = () => (
  <div className="relative flex flex-row h-16">
    <nav className="flex flex-row pt-6 z-20 absolute w-full">
      <div className="flex flex-col flex-grow relative">
        <div className="flex flex-row items-center">
          <Link className="h-6 w-6" to="/">
            <img src={LogoImage} className="absolute sm:fixed w-6 z-30" alt="Hochzehn Logo" />
          </Link>
          <Link className="h-5" to="/">
            <img className="ml-3 h-full" src={BrandImage} alt="Hochzehn Marke" />
          </Link>
        </div>
      </div>

      <div className="flex flex-col relative w-6 h-6">
        <Navigation />
      </div>
    </nav>
  </div>
);

export default Header;
