import React from 'react';
import { Link } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import CityLogo from './images/CityLogo';

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNavigation: false,
    };
  }

  componentDidUpdate(_, prevState) {
    const { showNavigation } = this.state;

    if (prevState.showNavigation === showNavigation) {
      return;
    }

    /* eslint-disable-next-line */
    const { body } = document;

    if (showNavigation) {
      body.classList.add('overflow-hidden');
    } else {
      body.classList.remove('overflow-hidden');
    }
  }

  componentWillUnmount() {
    /* eslint-disable-next-line */
    const { body } = document;
    body.classList.remove('overflow-hidden');
  }

  render() {
    const { showNavigation } = this.state;

    return (
      <>
        <div className={`hamburger sm:fixed z-20 ${showNavigation ? 'open' : ''}`} onClick={() => this.setState({ showNavigation: !showNavigation })}>
          <span />
          <span />
          <span />
        </div>
        <div className={`navigation ${showNavigation ? 'open' : 'hidden'}`}>
          <div className="navigation-background" />
          <div className="flex flex-row h-full justify-center items-start overflow-auto">
            <div className="flex flex-col z-10 justify-around py-12 min-h-screen">
              <div className="flex flex-col flex-no-shrink items-start w-screen max-w-xs p-4">
                <Link className="animate font-bold text-3xl md:text-4xl hover:text-white fade-object-in  animate-icon-in" activeClassName="is-active" to="/services">
                  Leistungen
                  <FontAwesomeIcon icon={faArrowRight} size="xs" />
                </Link>
                <Link className="mt-6 font-bold text-3xl md:text-4xl hover:text-white fade-object-in  animate-icon-in" activeClassName="is-active" to="/works">
                  Projekte
                  <FontAwesomeIcon icon={faArrowRight} size="xs" />
                </Link>
                <Link className="mt-6 font-bold text-3xl md:text-4xl hover:text-white fade-object-in  animate-icon-in" activeClassName="is-active" to="/about-us">
                  Über Uns
                  <FontAwesomeIcon icon={faArrowRight} size="xs" />
                </Link>
                <Link className="mt-6 font-bold text-3xl md:text-4xl hover:text-white fade-object-in  animate-icon-in" activeClassName="is-active" to="/career">
                  Karriere
                  <FontAwesomeIcon icon={faArrowRight} size="xs" />
                </Link>
              </div>

              <div className="flex flex-col flex-no-shrink items-start p-4 fade-object-in ">
                <span className="text-xl font-semibold uppercase">Kontakt</span>
                <br />
                <a className="text-white no-underline" href="mailto:hello@hochzehn.com">hello@hochzehn.com</a>
                <br />
                +49 (0) 251 149 823 30
                <br />
              </div>

              <div className="flex flex-col flex-no-shrink items-start p-4 fade-object-in ">
                <div className="w-16 md:w-24 mb-5">
                  <CityLogo />
                </div>
                <p className="uppercase text-left">
                  Zuhause in
                  <br />
                  Münster und Köln
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Navigation;
